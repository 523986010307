<template>
    <section class="smarta-accordion border-b-2 border-black -mx-8 sm:mx-0">
        <div
            v-for="(item, index) in items"
            :key="index"
            class="border-t-2 border-black"
        >
            <button
                :id="`accordion_${seed}_${index}_button`"
                :aria-controls="`accordion_${seed}_${index}_panel`"
                :aria-expanded="expanded === index"
                type="button"
                class="flex justify-between items-center w-full appearance-none px-8 py-8"
                @click="toggle(index)"
            >
                <span class="text-left text-2xl xl:text-3xl font-bold text-smarta-green-500">{{ item.title }}</span>
                <fa-icon
                    :icon="['fas', 'chevron-down']"
                    size="2x"
                    :class="{
                        'transition-transform': true,
                        'scale-y-100': expanded !== index,
                        '-scale-y-100': expanded === index
                    }"
                />
            </button>
            <div
                v-show="expanded === index"
                :id="`accordion_${seed}_${index}_panel`"
                :aria-hidden="expanded !== index"
                :aria-labelledby="`accordion_${seed}_${index}_button`"
                class="px-8 pb-8"
            >
                <div class="smarta-content" v-html="item.content" />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Accordion',

    props: {
        value: {
            type: Array,
            required: true
        }
    },

    data () {
        return {
            seed: null,
            expanded: null
        }
    },

    computed: {
        items () {
            return this.value
        }
    },

    created () {
        const seedMin = 100000
        const seedMax = 900000
        this.seed = Math.floor(Math.random() * (seedMax - seedMin) + seedMin)
    },

    methods: {
        toggle (index) {
            this.expanded = this.expanded === index ? null : index
        }
    }
}
</script>
